.main-wrapper{
    position: relative;
    background-color: $body-bg;
}
.banner-layer{
    position: relative;
    .overlay-banner{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        z-index: 3;
        background-color: rgba(0,0,0, 0.25);
    }
    .video-wrapper{
        position: relative;
        &::before{
            content: '';
            display: block;
            padding-top: 44.4%;
            
        }
        .video-wrap{
            position: absolute;
            top:0;
            left: 0;
            right: 0;
            bottom:0;
            video{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}
.banner{
    position: relative;
    width: 100%;
    padding-top: 6.2rem;
    padding-bottom: 11rem;
    @include flex-center;
    flex-direction: column;
    background-position:center !important;
    background-size:cover !important;
    &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        display: block;
        top: 0;
        z-index: 0;
        background: rgba(113, 103, 255, 0.92) !important;
    }
    &-text{
        position: relative;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        padding-top: 100px;
        .container{
            margin: 0 auto;
        }
        
    }
    &-heading{
        h1{
            font-weight: $bold;
            font-size:$font-xl;
            color: $white;
        }
        p{
            margin-bottom: 1.5rem;
            font-size: $font-md;
        }
    }
    .tour-service{
        height: 100px;
        width: 100px;
        display: block;
    }
}

.banner-tabs{
    position: absolute;
    right: 170px;
    max-width: 470px;
    width: 100%;
    top: 46%;
    transform: translate(0%, -40%);
    background: $white;
    border-radius: 8px;
    z-index: 1026;
    padding: 40px 30px;
    &-heading{
        h2{
            position: relative;
            @include font(24px, $heading);
            
            &::before{
                content: '';
                position: absolute;
                bottom:-5px;
                left: 0;
                height: 2px;
                @include border-radius(2px);
                width: 120px;
                background-color: $heading-border;
            }
        }
    }
    
    .nav-tabs{
        border: none !important;
        justify-content: space-between;
        .nav-link{
            border: none !important;
            flex-direction: column;
            height: 60px;
            width: 60px;
            max-width: 100%;
            position: relative;
            @include border-radius;
            @include flex-center;
            background:  transparent linear-gradient(0deg, rgba(246, 184, 1, 0.13) 0%, rgba(245, 146, 0, 0.13) 100%) 0% 0% no-repeat padding-box;;
            &.active{
                background: transparent linear-gradient(180deg, #F6B801 0%, #F59200 100%) 0% 0% no-repeat padding-box;
                .icon{
                    img{
                        filter: brightness(0) invert(1);
                    }
                }
                .text{
                    color: $active-color;
                }
            }
            .text{
                color: $text-color;
                font-size: 12px;
                position: absolute;
                bottom:-25px;
            }
        }
    }
    .tab-content{
        padding-top:50px;
        border-radius: 5px;
        
    }
    
}


.banner-search{
    .form-row{
        width: 100%;
    }
    .form-group-wrap{
        display: flex;
        align-items: center;
        .form-control{
            padding-left: 35px;
        }
    }
    
}
.banner-service{
    border-radius: 4px;
    cursor: pointer;
    a{
        display: block;
        height: 32px;
        width: 32px;
        overflow: hidden;
        margin:0 auto;
        
    }
    h6{
        color:$white;
        margin-top:10px;
        font-size: .875rem;
    }
    &:hover{
        background: rgba(0, 0, 0, 0.25);
        box-shadow: 5px 4px 12px 4px rgba(49, 40, 154, 0.25);
    }
}
.travel-search-tabs{
    .ant-tabs{
        &-nav{
            margin: 0;
            &::before{
                display: none;
            }
            &-list{
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }
            &-wrap{
                border: 0;
                &::before{
                    display: none;
                }
                &::after{
                    display: none;
                }
            }
        }
        &-tab{
            @include font;
            @include border-radius(5px 5px 0 0);
            padding: 10px 16px;
            text-align: center;
            letter-spacing: .5px;
            display: block;
            margin: 0;
            margin-left: 10px;
            &-active{
                background: rgba($color-1, 0.3);
                color: $white !important;
                @include border-radius(5px 5px 0 0);
                .ant-tabs-tab-btn{
                    color: $white !important;
                    font-weight: $regular;
                }
            }
        }
        &-tabpane{
            background: rgba(49, 45, 101, 0.3);
            padding: 15px;
            margin: 1px 5px 0 0;
            border-radius: 5px;
            
        }
        
    }
}

.tour{
    
    &-categories{
        width: 150px !important;
        height: 150px;
        overflow: hidden;
        @include border-radius(50%);
        padding-top: 23px !important;
        background: rgba($black, 0.1);
        &:hover{
            background: rgba($black, 0.25);
            box-shadow: 5px 4px 12px -4px rgba(49, 40, 154, 0.25%);
        }
        h6{
            text-transform: capitalize;
        }
    }
}


.bt{
    border-top:1px solid $color-3;
}

.video-wrapper{
    padding:96px 0;
}

.wrapper-title{
    h2{
        font-size: 50px;
        @include md{
            font-size: 40px;
        }
        @include rwd(375){
            font-size: 30px;
        }
        @include rwd(300){
            font-size: 25px;
        }
    }
    p{
        line-height: 1.5 !important;
        font-size: $font-regular;
    }
    .play-video{
        .btn-primary{
            box-shadow: 0 0 0 rgba(255, 255, 255, 0.9);
            animation: pulse 2s infinite;
            animation-duration: .9s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            @include flex-center;
            font-size: 1.875rem;
            background: #fdfdfd1a;
            border: 6px solid rgba(255, 255, 255, 0.05);
            margin:0 auto;
            &:hover{
                cursor: pointer;
            }
            svg{
                margin: 0 0 0 7px;
                color: rgba(255, 255, 255, 0.8) !important
            }
            
        }
    }
}
.counting-wrapper{
    .counter-wrap{
        text-align: center;
        @include lg{
            margin-bottom: 20px;
        }
        @include sm{
            margin-bottom: 10px;
        }
        .counter-imag{
            @include flex-center;
            width: 4rem;
            height: 4rem;
            border-radius: 50%;
            text-align: center;
            margin: 0 auto;
            i{
                color:$white;
                font-size: 1.2rem;
            }
            
        }
        p{
            margin-bottom: 1rem !important;
            font-weight: $medium;
            font-size: $font-regular;
        }
        h2{
            font-weight: $bold;
            font-size: 2.1875rem;
            margin-top: 0px;
            color: $primary-color;
        }
    }
}
.search-destination-wrapper {
    padding-bottom: 96px;
    padding-top:96px;
    &.bg-cover{
        &::before{
            background: rgba(113, 103, 255, 0.8) !important;
        }
    }
}
.search-wrap{
    .ant-input-affix-wrapper {
        height: 46px;
        box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.3%);
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;    
    }
    .ant-input-group-addon{
        border-radius: 5px;
    }
    .ant-btn-primary{
        padding: 0.5rem 1rem;
        font-size: 1.125rem;
        line-height: 28px;
        height: 46px;
        background-color: $secondary-color !important;
        box-shadow: 0px 0px 12px 0px rgba($secondary-color, 0.35);
        border-color: $secondary-color;
        border-top-right-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
        &:hover{
            border-color: $secondary-color;
        }
    }
}

.download-wrapper{
    .group-btn{
        flex-wrap: wrap;
        .btn{
            margin-right: 10px;
            padding: 0.5rem 1rem;
            font-size: 1.125rem;
            line-height: 28px;
            border-radius: 5px;
            @include rwd(327){
                width: 100%;
            }
        }
        .btn-primary{
            @include rwd(332){
                margin-top:15px;
            }
        }
        .btn-info{
            @include rwd(469){
                margin-top:15px;
            }
        }
    }
}

.destination-tabs{
    .nav{
        border:0;
        justify-content: center;
        @include md{
            justify-content: flex-start;
        }
        
    }
    .tab-content{
        padding:40px 0;
    }
}
.tabs-destinations{
    .nav-item{
        padding: 7px 20px 7px 20px;
        color: #312d65;
        background: #fff ;
        margin: 0 10px;
        border-radius: 5px;
        display: block;
        border: 1px solid #e5e4f0;
        box-shadow: 0px 0px 12px 0px rgba(79, 37, 225, 0.03);
        @include md{
            margin-bottom: 10px;
        }
        &.active{
            color:$white !important;
            background-color: $secondary-color !important;
        }
    }
}
.feature-layer{
    .card{
        @include lg{
            margin-bottom: 20px !important;
        }
    }
}
.br-0{
    border-radius: 0;
}
.b-0{
    border:0 !important;
}
.date-picker-inner{
    position: relative;
}

.star{fill:#febb02;}
.star-rating{
    svg{
        margin-right: 5px;
    }
}

.popup-wrappers{
    position: absolute;
    max-height: 175px;
    background: #f5f6f8;
    z-index: 3;
    width: 100%;
    box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.15);
    top: 85px;
    border: 1px solid #ececec;
    overflow: auto;
    &.hidden{
        display: none;
    }
    ul{
        margin-bottom: 0;
        li{
            padding: 10px 20px;
            color: $text-color;
            span{
                color: $heading-border;
            }
            &:hover{
                background-color: $border-color;
            }
        }
    }
}
// hotel by id 
.hotel-detail-wrapper{
    font-family: $rubik;
    color: $color-7;
    font-weight: $regular;
}
.hotel-title {
    color:$color-7;
    .badge{
        font-weight: 500;
        text-transform: capitalize;
        padding: 8px 10px ;
        letter-spacing: 0.5px;
        font-size: 12px;
    }
    h3{
        font-size: 22px;
        text-transform: capitalize;
    }
}   

.hotel-gallery{
    .row{
        margin-left: -5px;
        margin-right: -5px;
    }
    .p-10{
        padding-left: 5px;
        padding-right: 5px;
    }
    .gallery-img{
        -webkit-box-shadow: 0 0 5px 0 #ccc;
        box-shadow: 0 0 5px 0 #ccc;
        text-align: center;
        cursor: pointer;
        margin-bottom: 10px;
        
        &.grid-1{
            height: 370px;
        }
        &.grid-2{
            height: 200px;
        }
        &.grid-4{
            height: 160px;
        }
    }
}
.hotel-info{
    
    .heading{
        h5{
            padding-bottom: 10px;
            border-bottom: 1px solid $color-2;
            text-transform: capitalize;
        }
    }
    .hotel-description{
        @include border-radius(5px);
        p{
            line-height: 28px;
            
        }
    }
    .hotel-feature{
        @extend .hotel-description;
        .feature-wrapper{
            flex-wrap: wrap;
            .feature-item{
                flex: 1 0 auto;
                text-align: center;
                p{
                    font-size:$font-xs;
                }
            }
        }
    }
}
.choose-room{
    
    &-item{
        padding:20px;
        margin-bottom: 30px;
        border-radius: 5px;
        &:last-child{
            margin-bottom: 50px;
        }
        h5{
            font-weight: $regular;
        }
        small{
            color: $label;
        }
        .choose-bed{
            p{
                font-size: $font-regular;
            }
            .radio-img  > input { 
                display:none;
            }
            
            .radio-img  > .bed-btn{
                cursor:pointer;
                border: 1px solid #ECECEC;
                border-radius: 5px;
                background: #F5F6F8;
                width: 192px;
                margin-right: 12px;
                padding: 15px 20px 15px 15px;
            }
            .bed-img{
                flex-shrink: 0;
            }
            .bed-text{
                padding-left: 15px;
                small{
                    font-size: 14px;
                }
            }
            
            .radio-img  > input:checked ~ .bed-btn{
                border:2px solid orange;
                background: transparent linear-gradient(90deg, #F6B801 0%, #F59200 100%) 0% 0% no-repeat padding-box;
                border: 1px solid #ECECEC;
                small{
                    color: $white;
                    
                }
            }
            
        }
    }
}

.features{
    h6{
        font-weight: $regular;
        text-transform: capitalize;
    }
    &-content{
        background-color: #FFFCF5;
        padding: 10px 20px;
        @include border-radius(5px);
        overflow-x: auto;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    &-item{
        flex: 1 1 auto;
        text-align: center;
        img{
            height: 26px;
            width: 26px;
        }
        p{
            @include font(13px, $color-8);
        }
    }
    &-others{
        li {
            padding-left: 1.3em;
            margin-right: 15px;
            font-size: 14px;
            color:$color-8;
        }
        li:before {
            content: "\f00c"; 
            font-family: FontAwesome;
            display: inline-block;
            margin-left: -1.3em; 
            width: 1.5em; 
            color: $active-color;
        }
        &:last-child{
            margin: 0;
            &::before{
                display: none;
            }
        }
        &.see-more{
            text-decoration: underline;
            color: $active-color;
        }
    }
    
}
.choose-room-container{
    background-color: #fff;
}
.choose-room-table {
    table{
        width: 100%;
        thead{
            background-color: #F5F6F8;
            th{
                font-weight: $medium;
                text-transform: capitalize;
            }
            
        }
        th, td{
            padding:10px 20px;
            border: 1px solid #ECECEC;
        }
        td{
            vertical-align: top;
            background-color: #F5F6F8;
        }
    }
    .price{
        font-weight: 500;
        .text{
            font-size: 20px;
            padding-left: 5px;
            color: $info;
        }
    }
}

.choose-room-quantity{
    width: 135px;
}

.sticky-reserve-detail{
    background: #ebeef1;
    position: sticky;
    top: 130px;
    min-height: 100px;
    padding: 20px;
    .btn{
        height: 48px;
    }
}

.guest-item-wrap{
    label{
        margin: 0;
        color: $color-7;
        font-size: $font-sm;
    }
    .btn-outline{
        border: 1px solid $heading-border;
        padding:5px 10px;
        color: $heading-border;
        line-height: 14px;
        height: auto  !important;
        @include flex-center;
        &:hover{
            background-color: $heading-border;
            color: $white;
        }
    }
    &:hover{
        background-color: transparent !important;
    }
}
.count-value{
    width: 40px;
}

.bed-btns{
    cursor: pointer;
    border-radius: 5px;
    background: #F5F6F8;
    margin-right: 12px;
    padding: 5px 15px;
    border: 1px solid #ECECEC;
}

.room-structure{
    align-items: center;
    p{
        margin-right: 15px;
        font-size: 14px !important;
        color: #1d1d1d;
        font-weight: 600;
        span{
            padding-left: 5px;
            font-weight: 400;
        }
    }
    
}

.for-breakfast{
        color:#017D2B;
        font-size: 12px;
}

.booking-success{
    .success-circle{
        height: 60px;
        width: 60px;
        margin: 0 auto;
        @include flex-center;
        color: #fff;
        border-radius: 50%;
        font-size: 28px;
        margin-bottom: 10px;
    }
    .btn{
        height: 48px;
    }
}

.navbar-brand{
    height: 58px;
    img{
        max-width: 58px;
    }
}

.custom-drops{
    transform: translate3d(-130px, 40px, 0px) !important;
}