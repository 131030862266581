.react-daterange-picker__inputGroup{
    flex-grow: unset;
    input{
        cursor: default;
        @include font($font-sm, $color-7);
        &:focus{
            outline: none;
        }
    }
    &:hover{
        cursor: default;
    }
}