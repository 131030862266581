p,h1,h2,h3,h4,h5,h6{
  margin:0;
}

h1,h2,h3,h4,h5,h6{
  line-height: 1.4;
}
.heading{
  h3{
      @include font($font-lg-1, $active-color, $bold);
      font-family: 'Dancing Script', cursive;
  }
  h2{
     @include font(34px, $label, $medium);
     font-family: $rubik;
        margin-bottom: 8px;
        @include sm{
          font-size: 24px;
        }
  }
  p{
     @include font($font-regular, $color-2);
  }
}
.white-heading{
  z-index: 3;
  position: relative;
  h3{
    color: rgba(255, 255, 255, 0.8) !important;
  }
h2{
    color:$white;
  }
}
.text-heart{
  color:#ec296b;
}