.card{
    overflow:hidden;
    @include border-radius(8px);
    border: 1px solid #e5e4f0;
    box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
    @include md{
        margin-bottom: 20px;
    }
    &-outer-layer{
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 5px 4px 12px 4px rgba(79, 37, 225, 0.03);
        &:before{
            content:'';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            right: 0;
            top: 0;
            z-index: 1;
            background: rgba(113, 103, 255, 0.1)
        }
    }
    &-link-overlay{
        @include absolute;
        left: 0;
        bottom: 0;
    }
    &-icons{
        @include absolute(absolute,15px, 15px);
        z-index: 9;
        display: flex;
        .item-circle{
            height: 32px;
            width: 32px;
            line-height: 2.1rem;
            color: #fff;
            @include border-radius(50%);
            margin-left: 5px; 
            background-color: rgba(49, 45, 101, 0.65);
            &:hover{
                background-color: $secondary-color;
            }
        }
    }
    &-ribbon{
        position: absolute;
        overflow: hidden;
        width: 56px;
        height: 56px;
        z-index: 10;
        span{
            position: absolute;
            display: block;
            width: 87px;
            padding: 8px 0;
            color: $white;
            text-shadow: 0 1px 1px rgba(49, 45, 101, 0.02);
            text-transform: capitalize;
            text-align: center;
            
        }
        &.ribbon-top-left{
            top: -6px;
            left: -9px;
            span{
                right: -5px;
                top: 1px;
                transform: rotate(-45deg);
                svg{
                    transform: rotate(45deg );
                    padding-top: 2px;
                    padding-left: 7px;
                    width:20px
                }
            }
        }
    }
    &-offer{
        position: absolute;
        width: 80px;
        height: 80px;
        top: -20px;
        text-align: center;
        padding-top: 20px;
        border-radius: 0 30% 50% 30%;
        left: -20px;
        transition: all 0.4s;
        opacity: 1;
        z-index: 2;
        h2{
            @include font($font-regular, $primary-color, $bold);
            padding-top: 9px;
            padding-left: 20px;
        }
        p{
            span{
                display:none
            }
            font-size: 0.75rem;
            padding-left: 12px;
        }
        &.bg-success, &.bg-secondary{
            h2,p{
                color:$white;
            }
        }
    }
    &-img-top{
        position: relative;
        overflow: hidden;
        img{
            transform: scale(1.1);
            cursor: pointer;
            @include transition(.3s ease-in-out);
        }
        &:hover{
            img{
                transform: scale(1);
                
            }
        }
        .pricing{
            position: absolute;
            bottom: 20px;
            right: 0;
            color: $white;
            z-index: 2;
            padding: 7px 25px;
            border-radius: 5px 0 0 5px;
            h4{
                @include font($font-md, $white, 600 !important);
            }
        }
    }
    &-title{
        font-weight: $bold;
        font-size: $font-md;
        line-height:1.4;
    }
    &-body{
        &-heading{
            a{
                font-weight: $bold;
                font-size: $font-md;
            }
        }
        &-text{
            p{
                color: $primary-color;
                font-size: $font-sm;
                .days, .time{
                    font-weight: $bold;
                }
            }
        }
    }
    &-footer{
        padding:16px 24px;
        background-color: $white;
        border-top: 1px solid $color-3;
        
    }
}

.rating{
    &-inner{
        color: $color-6;
    }
}

.popular-package-wrapper{
    .card{
        // max-width: 362px;
        display: block !important;
        margin: 0 auto;
    }
}

.rated-slider{
    .card{
       
        &-img{
            position: relative;
            overflow: hidden;
            img{
                cursor: pointer;
                width: 100%;
                @include transition(.3s ease-in-out);
            }
            &:hover{
                img{
                    transform: scale(1.1);
                    
                }
            }
        }
        &-img-body{
            position: absolute;
            bottom: 0;
            left:0;
            right: 0;
            padding:20px;
            background: linear-gradient(rgba(49, 45, 101, 0.1) 10%, rgba(49, 45, 101, 0.35) 100%);
            transition: all .5s;
            h4{
                @include font($font-regular, $white, 600);
            }
        }
    }
}

.tour-places-slider{
    .slick-slide{
        div{
            max-width: 560px;
            overflow: hidden;
        }
    }
    .card-outer-layer{
        &::before{
            background: rgba(49, 45, 101, 0.6) !important;
            border-radius: 8px;
        }
    }
}

.tour-place-card{
    height: 350px;
    overflow: hidden;
    background-position: center center !important;
    .card-body{
        margin-top: 50px;
        padding:4rem;
        cursor: pointer;
        position: relative;
        z-index: 10;
        @include transition(all 0.4s);
        p{
            font-size: $font-sm;
            line-height: 2 ;
        }
        .btn{
            padding: 0.5rem 2rem;
            font-size: 1.125rem;
            line-height: 28px;
            border-radius: 5px;
            height: 46px;
            opacity: 0;
            bottom: 40px;
            @include transition(all 0.4s);
            border-color: $secondary-color !important;
        }
        &:hover{
            @include transition(all 0.4s);
            margin-top: 5px;
            .btn{
                display: inline-block;
                bottom: 45px;
                opacity: 1;
                @include transition(all 0.4s);
            }
        }
    }
}

.tour-package-layer{
    .card{
        &-img{
            position: relative;
            &-header{
                position: absolute;
                bottom:0;
                left: 0;
                right:0;
                width: 100%;
                background-color: #e5e4f0;
                background-color: rgba(49, 45, 101, 0.6) !important;
                h4{
                    font-size: $font-md;
                }
            }
        }
        &-body{
            .package-details{
                h4{
                    line-height: 1.4;
                    @include font($font-regular, $color-1, $bold)
                }
                .price{
                    @include font(20px, $color-1, $bold)
                    
                }
                .day{
                    font-size: $font-sm;
                }
                .ticket{
                    margin-bottom: 16px;
                    font-size: $font-xs;
                    .text-danger{
                        @extend .day;
                    }
                }
            }
        }
    }
}

.transporation-wrapper{
    @extend .popular-package-wrapper;
    .card{
        &-icons{
            a{
                background: rgba(49, 45, 101, 0.65);
            }
        }
        .facilities{
            a{
                display: block;
                svg{
                    color:$color-4  !important;
                }
            }
        }
    }
}

.resturants-wrapper{
    .title-destination{
        left:10px;
        // bottom: 10px;
        color: $heading;
        padding:3px 20px;
        font-weight: $bold !important;
        text-transform: capitalize;
    }
    .resturants-details{

        a{
            margin-bottom: 10px;
            @include flex-y-center;
        }
        .time{
            @extend a;
            .badge{
                font-size: 13px;
                &-success{
                    color:$white !important;
                    background-color: #3eb750 !important;
                }
                &-danger{
                    color:$white !important;
                    background-color: $secondary-color !important;
                   
                }
            }
        }
        .circle{
            // margin: 0 auto;
            text-align: center;
            font-size: 0.75rem;
            width: 25px;
            height: 25px;
            background: $color-3;
            line-height: 25px;
            border-radius: 50%;
            @include flex-center;
        }
    }
}
.holiday-wrapper{
    .card{
        &-title{
            line-height: 1.125rem;
            small{
                display: block;
                font-size: $font-xs;
            }
        }
        &-img-wrap{
            cursor: pointer;
            overflow: hidden;
            @include transition(.3s ease-in-out);
            &::before{
                content:'';
                display: block;
                background: rgba(49, 45, 101, 0);
                position: absolute;
                width:100%;
                height: 100%;
                top:0;
                right:0;
                z-index: 1;
            }
            img{
                transform: scale(1);
                background-size: cover !important;
                width:100%;
                position: relative;
                @include transition(.3s ease-in-out);
            }
            &:hover{
                img{
                transform: scale(1.1);
                }
            }
        }
        .holiday-cities{
            position: absolute;
            right:20px;
            z-index: 3;
            bottom:20px;
            border-radius: 5px;
            padding:7px 15px;
            background: linear-gradient(rgba(49, 45, 101, 0.75) 10%, rgba(49, 45, 101, 0.75));
        }
        .title-destination{
            bottom:auto;
            right:auto;
            left:20px;
            top:20px;
        }
    }
}
.feature{
    &-img{
        width: 80px;
        height: 80px;
        @include border-radius;
        position: relative;
        background: rgba(113, 103, 255, 0.1);
        @include flex-center;
        svg{
            font-size: 30px;
        }
    }
}

.blog-list-wrapper{
    .bg-cover{
        &::before{
            background-color: rgba(49, 45, 101, 0.6) !important;
        }
        
    }
    .card{
        // height: 335px;
        margin-bottom: 24px !important;
        &-body{
            margin:0;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding:24px;
            a{
                @include font($font-md, $white, $bold);
            }
            
        }
    }
}

.customer-slider{
    .card{
        position: relative;
        &-body{
            padding: 1.5rem 1.5rem;
        }
        &-side{
            position: absolute;
            left: 0;
            top:0;
            bottom:0;
            width: 65px;
            height: 100%;
        }
    }
    .customer-avatar{
        background: $secondary no-repeat center/cover;
        @include border-radius(50%);
        width: 5rem !important;
        height: 5rem;
        line-height: 5rem;
        font-size: 2rem;
        overflow: hidden;
        display: flex;
        flex-shrink: 0;
        img{
            height: 100%;
            width: 100%;
        }
    }
    .customer-reviews{
        h3{
            @include font($font-regular, $primary-color, $bold);
        }
    }
    
}

.card-img-wrap{
    position: relative;
    overflow: hidden;
    img{
        transform: scale(1.1);
        cursor: pointer;
        @include transition(.3s ease-in-out);
    }
    &:hover{
        img{
            transform: scale(1);
            
        }
    }
}
.title-destination{
    position: absolute;
    bottom:20px;
    left:20px;
    z-index: 2;
    padding: 5px 12px;
    color: #fff;
    border-radius: 0.3125rem;
    font-weight: 400 !important;
    span{
        text-transform: capitalize;
    }
    
}

.views-wrap{
    font-size: $font-xs;
}
.persona{
    .img-wrap{
        width: 35px;
        height: 35px;
        background: #f96768 no-repeat center/cover;
        @include border-radius(50%);
        overflow: hidden;
    }
    .user-title{
        font-size: 0.875rem;
    }
}

.animated-background {
    width: 980px;
    height: 898px;
    margin: 20px auto;
  }
  
  @keyframes placeHolderShimmer{
      0%{
          background-position: -490px 0
      }
      100%{
          background-position: 490px 0
      }
  }
  
  .animated-background {
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-name: placeHolderShimmer;
      animation-timing-function: linear;
  
    background: #f6f7f8;
    background-image: -webkit-gradient(linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8));
    background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
      background-size: 980px 898px;
      height: 898px;
      position: relative;
  }