.ptb-100{
    padding: 100px 0;
}
.heading-2{
    h3{
        color: #f96768;
        // padding: 8px 20px;
        // color:$white;
        display: inline-block;
        font-size: 32px;
        font-weight: 500;
        font-family: 'Dancing Script', cursive;
    }
    p{
        margin-top: 20px;
        text-transform: capitalize;
        position: relative;
        font-size: 32px;
        font-weight: 500;
        &::before{
            content: '';
            height: 2px;
            background-color: $color-4;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);
            right: 102%;
        }
    }
}

.contents{
    p{
        line-height: 28px;
        font-weight: 300;
    }
    .btn-none{
        background: transparent;
        border: none;
        font-size: 16px;
        padding: 0;
    }
}