.form-main-wrapper{
    max-width: 500px;
    padding: 30px 20px;
    margin: 0 auto;
    background-color: $white;
    border-radius: 6px;
}

.authenticate{
    padding: 150px 0 100px;
}
.other-media{
    p{
        text-transform: capitalize;
        // font-size: 16px;
        position: relative;
        &::before, &::after{
            content: '';
            position: absolute;
            height: 1px;
            background-color: $color-3;
            width: 150px;
            top: 11px;
        }
        &::before{
            left: 0;
            right: 100%;
        }
        &::after{
            right: 0;
        }
    }
}

.register-form{
    h3{
    font-size: 20px;
    font-weight: $regular;
    margin-bottom: 5px;
    }
    .btn-primary{
        width: 100% !important;
        text-transform: uppercase;
    }
    .head{
        p{
            span{
                color: $active-color;
                cursor: pointer;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
    }
}
.medias{
    margin-right: 10px;
    flex:1;
    &:last-child{
        margin-right: 0;
    }
    .btn{
        width: 100%;
        height: 48px;
    }
    &.media-g{
        .btn-outline-primary{
            border-color: $google;
            color:$google;
            &:hover{
                background-color: $google;
                color:$white;
            }
        }
    }
    &.media-f{
        .btn-outline-primary{
            border-color: $fb;
            color:$fb;
            &:hover{
                background-color: $fb;
                color:$white;
            }
        }
    }
}