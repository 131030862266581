
 // Custom devices
@mixin rwd($screen) {
   @media (max-width: $screen+'px') {
       @content;
   }
}

 // Extra large devices
 @mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
 }

  // Large devices
  @mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
 }

  // Medium devices
  @mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
 }

 @mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
 }