$white: #ffffff;
$black: #000000;

$primary-color: #312d65;
$secondary-color:#f96768 ;
$form-bg:#F5F6F8;
$body-bg:#f1f4f3;
$bg-color:#eff3f6;
$bg-color-2:#282f3f;
$heading:#030303;
$active-color:#F59200;
$heading-border:#F6A800;
$info:#FF8000;
$muted:#797896;
$border-color:#ECECEC;
$text-color:#777777;
$label:#555555;
$success:#38B919;
$error:#D91E22;
$google:#db4437;
$fb:#4267B2;

$color-1:#312d65;
$color-2:#a1a0b9;
$color-3:#e5e4f0;
$color-4:#7167ff;
$color-6:#ffb609;
$color-7:#222222;
$color-8:#333333;


$color-8:#1d1d1d;
$color-9:#f6b800;


// FONTS  SIZE
$font-xl: 48px;
$font-lg-1: 32px;
$font-lg-md: 24px;
$font-lg: 22px;
$font-md:18px;
$font-regular: 16px;
$font-sm: 14px;
$font-xs: 12px;


// FONTS  WIGHT
$bold:700;
$medium:500;
$regular:400;
$light:300;


// font family 
$rubik:'Rubik', sans-serif;
$popins:'Poppins', sans-serif;
// media queries 

// Small tablets and large smartphones (landscape view)
$screen-sm-max: 575px;

// Small tablets (portrait view)
$screen-md-max: 767px;

// Tablets and small desktops
$screen-lg-max: 991px;

// Large tablets and desktops
$screen-xl-max: 1199px;
