.mt-05{
  margin-top: 5px;
}
.mb-05{
  margin-bottom: 5px;
}
.mt-10, .mtb-10{
  margin-top:10px;
}

.mb-10, .mtb-10{
  margin-bottom:10px;
}
.mb-15{
  margin-bottom:15px;
}
.mt-20{
  margin-top:20px;
}
.mb-20{
  margin-bottom:20px;
}
.mr-30{
  margin-right:30px;
}
.mb-30{
  margin-bottom:30px;
}


.mr-25{
  margin-right:25px;
}
.mt-25{
  margin-top:25px;
}
.mb-25{
  margin-bottom:25px;
}

.pl-35{
  padding-left: 35px;
}

.ml-auto{
  margin-left: auto;
}

.pt-48, .ptb-48{
  padding-top:48px;
}
.pb-48, .ptb-48{
  padding-bottom:48px;
}
.mt-50{
  margin-top: 50px;
}
.mt-120{
  margin-top: 120px;
}