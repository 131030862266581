.footer{
    background-color: $bg-color-2;
    &-top{
        color: rgba(255, 255, 255, 0.5) !important;
        ul{
            margin:0;
            padding:0;
            flex-grow: 1;
            @include md{
                justify-content: center;
            }
            li{
                margin-right: 15px;
                a{
                    font-size: 20px;
                    color:rgba(255, 255, 255, 0.6);
                    &:hover{
                        color:$secondary-color;
                    }
                }
            }
        }
        .select-type{
            @include md{
                margin-top:20px;
                justify-content: center;
            }
            .ant-select{
                width:180px;
                
                
                .ant-select-selector{
                    height: 40px;
                    border-radius: 5px;
                    background: transparent;
                    border: 1px solid rgba(255, 255, 255, 0.2) !important;
                    @include flex-y-center;
                    color: $white;
                    &:focus, &.active{
                        outline: none;
                        box-shadow: none;
                    }
                }
                .ant-select-arrow{
                    svg{
                        color: $white;
                    }
                }
            }
            .ant-select-open{
                .ant-select-arrow{
                    transform: rotate(180deg );
                }
            }
        }
    }
    &-main{
        padding: 2rem 0;
        border-top: 1px solid rgba(167, 180, 201, 0.2);
        border-bottom: 1px solid rgba(167, 180, 201, 0.2);
        .travel-footer-des{
            h6{
                font-size: $font-regular;
                color:$white;
                margin-bottom: 1.2rem;
                @include md{
                    margin-top:20px;
                }
            }
            ul{
                li{
                    a{
                        line-height: 2;
                        transition: opacity .2s;
                        color: rgba(255, 255, 255, 0.6);
                        &:hover{
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
        .social-links{
            li{
                font-size: 0.875rem;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                border: 1px solid rgba(255, 255, 255, 0.2);
                line-height: 35px;
                margin: 1px;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background-color: $secondary-color;
                }
                a{
                    color:rgba(255, 255, 255, 0.5) !important;
                    i{
                        color:rgba(255, 255, 255, 0.5) !important;
                    }
                }
            }
        }
        .touch-links{
            li{
                a{
                    i{
                        font-size: $font-xs;
                    }
                }
            }
        }
    }
    &-bottom{
       
            p{
                color: rgba(255, 255, 255, 0.5) !important;
                .text-secondary{
                    font-size:$font-sm;
                }
            }
        
    }
}