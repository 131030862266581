.btn-primary, .ant-btn-primary{
    color: #fff !important;
    background: transparent linear-gradient(90deg, #F6B801 0%, #F59200 100%) 0% 0% no-repeat padding-box;
    font-size: $font-sm;
    border: none;
    @include border-radius(2px);
    width: 100%;
    &:hover{
        background-color: $info;
    }
}

.btn-secondary, .ant-btn-secondary{
    color: $white !important;
    background-color: $secondary-color !important;
    box-shadow: 0px 0px 12px 0px rgba($secondary-color, 0.35);
    border-color: $secondary-color;
    &:hover{
        border-color: $secondary-color;
    }
}

// .btn-primary-outline{
//     border:1px solid $active-color;
//     color: $active-color;
// }