.searchWrapper{
    .page-header{
        background: #fff;
        border-bottom: 1px solid #c0c0c0;
        @include flex-y-center;
        &.sticky{
            position: relative;
            box-shadow: none;
        }
        .navbar-nav{
            .nav-link{
                color:$text-color;
            }
        }
    }
}

.search-outer-wrapper {
    position: relative;
    top: 80px;
    background: $white;
    .banner-search{
        padding:20px 0;
        .row{
            flex-wrap: nowrap;
            align-items: flex-end;
            .form-row{
                padding-right: 15px;
                width: auto;
                flex-grow: 1;
                &:nth-last-child(2){
                    flex-grow: 2;
                }
                &:last-child{
                    padding:0;
                    flex-grow: 2;
                }
                .form-group{
                    margin: 0;
                }
                .form-group-wrap {
                    margin: 0 !important;
                   
                    
                }
            }
        }
    }
}
.sticky-search{
    position: sticky;
    top: 0;
    z-index: 9;
    box-shadow: 2px 3px 4.7px 0.3px rgba(49, 45, 101, 0.24);
    
}


.search-result-wrapper{
    position: relative;
    
    .result-wrapper{
        h3{
            @include font($font-lg, $heading, $medium);
        }
        .result-card-wrapper{
            .result-card{
                background-color: $white;
                padding:20px 30px 20px 15px;
                @include border-radius(5px);
                .img-wrap{
                    height: 192px;
                    width: 192px;
                    img{
                        max-width: 192px;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include border-radius(5px);
                    }
                }
                .search-hotel-detail-wrap{
                    font-family: $rubik;
                    h4{
                        @include font(20px, $color-7, $regular);
                        font-family: $popins;
                    }
                    small{
                        @include font($font-sm, $label, $regular);
                    }
                    .rating-badge{
                        margin-top: 10px;
                        margin-bottom: 15px;
                        
                        .badge{
                            height: 20px;
                            @include flex-center;
                            padding:0 12px;
                            font-weight: $regular;
                            margin-right: 12px;
                            font-size: $font-xs;
                            @include border-radius(2px);
                        }
                        .count{
                            @include font($font-xs, $text-color, $regular);
                        }
                    }
                    .room-type{
                        flex-wrap: wrap;
                        p{
                            @include font($font-sm, $label, $regular);
                            margin-right: 25px;
                            margin-bottom: 5px;
                            &:last-child{
                                margin-left: 0;
                            }
                        }
                    }
                    .items-available {
                        ul{
                            display: flex;
                            margin: 0;
                        }
                        li{
                            font-size: $font-xs;
                            margin-right: 20px;
                        }
                    }
                    .info{
                        @include font($font-xs, $info, $regular);
                        
                    }
                }
            }
            .search-result-pricing{
                font-size: $font-xs;
                align-self: flex-end;
                text-align: right;
                h2{
                    @include font(20px, $color-8, $regular);
                    line-height: 24px;
                }
                small{
                    @include font($font-xs, $text-color);
                }
                button{
                    max-width: 132px;
                }
            }
        }
    }
}

.react-daterange-picker__wrapper{
    height: 100%;
    border: none;
}
.react-daterange-picker__clear-button, .react-daterange-picker__calendar-button{
    display: none;
}
.react-daterange-picker__calendar{
    z-index: 4;
}

.filter-price{
    .input-range__label-container{
        left: auto;
    }
    .input-range__slider-container{
        .input-range__label--value {
            top: -2rem;
        }
        .input-range__label-container{
            padding-bottom: 10px;
            left: -80%;
        }
    }
}

.profile-img{
    height: 20px;
    width: 20px;
    @include flex-center;
    border: 1px solid red;
    border-radius: 50%;
    overflow: hidden;
    img{
        max-width: 20px;
    }
}