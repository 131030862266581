
.page-header{
    background-color: rgba(0, 0, 0, 0.05);
    width: 100%;
    z-index: 18;
    position: absolute;
    left: 0;
    right: 0;
    height: 80px;
    border-bottom: 1px solid $white;
    .navbar{
        padding-left: 0;
        padding-right: 0;
        .container-fluid{
            padding: 0;
        }
    }
    .navbar-nav{
        .nav-item{
            position: relative;
            .nav-link{
                padding: 9px 20px;
                color:$white;
                display: flex;
                align-items: center;
                margin: 0 1px;
                &.active{
                    color: $heading-border;
                }
            }
            .custom-dropdown-menu{
                position: absolute;
                top: 100%;
                left: 0;
                list-style: none;
                padding: 0;
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease;
                border-radius: 12px;
                transform: scaleY(0);
                transform-origin: left top;
                min-width: 200px;
                z-index: 5;
                .menu-inner{
                    position: relative;
                    z-index: 2;
                    background-color: $white;
                    padding: 10px;
                    border-radius: 4px;
                    a{
                        color: #6c6a8d;
                        text-decoration: none;
                        display: inline-block;
                        padding: 8px 16px;
                        &:hover, &.active{
                            color: #7167ff;
                        }
                    }
                }
                &::before{
                    position: absolute;
                    top: -5px;
                    left: 20px;
                    height: 10px;
                    width: 10px;
                    background-color: $white;
                    transform: rotate(45deg);
                    content: '';
                    z-index: 1;
                }
            }
            &:hover{
                .custom-dropdown-menu{
                    visibility: visible;
                    opacity: 1;
                    transform: scaleY(1);
                }
            }
            .btn-secondary{
                font-size: 13px;
                // line-height: 23px;
                height: 37px;
                @include flex-center
            }
        }
        @media screen and (max-width: 1199px){
            display: none;
        }
    }
    &.sticky{
        position: fixed;
        top: 0px;
        background: #fff !important;
        box-shadow: 2px 3px 4.7px 0.3px rgba(49, 45, 101, 0.24);
        height: 80px !important;
        padding: 0;
        @include transition(all .5s ease);
        @include flex-y-center;
        .logo-color{
            display: block;
        }
        .navbar-nav{
            .nav-item{
                position: relative;
                .nav-link{
                    color:$text-color;
                    &.active{
                        color: $heading-border;
                    }
                }
            }
        }
        .add-link-sm{
            color:$primary-color;
        }
        .header-inner{
            .btn{
            color:$primary-color;

            }
        }
    }
    .add-link-sm{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid;
        border-radius: 50%;
        flex-shrink: 0;
        height: 30px;
        width: 30px;
        color:$white;
    }
   
}
.header-inner{
    .btn{
        color: $white;
    }
}
.sidebar-navigation{
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    bottom: 0;
    height: 100vh;
    width: 240px;
    padding: 0 10px;
    transition: all 0.3s ease;
    z-index: 99;
    
    .sidenav-trigger{
        position: absolute;
        top: 0;
        left: 100%;
        background-color: $primary;
        color: $white !important;
        border-radius: 0;
        i{
             color: $white !important;
        }
    }
    .sidebar-inner{
        height: 100vh;
        overflow-y: auto;
    }
    .accordion{
        .btn{
            color: #6c6a8d;
            padding: 10px 0;
            &:hover{
                color: #7167ff;
            }
            &::before{
                transition: all 0.3s ease;
            }
        }
        .btn[aria-expanded="true"]{
            position: relative;
            &::before{
                content: '\f077';
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(0deg);
                right: 0;
            }
        }
        .btn[aria-expanded="false"]{
            position: relative;
            &::before{
                content: '\f077';
                font-family: FontAwesome;
                position: absolute;
                top: 50%;
                transform: translateY(-50%) rotate(180deg);
                right: 0;
            }
        }
    }
    &.display{
        left: 0;
    }
    @media screen and (min-width: 1200px) {
        display: none;
    }
}

.user-log{
    background: transparent !important;
    padding: 0 !important;
    border:0;
    &:active, &:focus{
        outline: 0 !important;
        box-shadow: none !important
    }
    &::after{
        display: none;
    }
}

.user-circle{
    height: 32px;
    width: 32px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid #8888;
    overflow: hidden;
    img{
        max-width: 30px;
    }
}
