// .form-control{
//      @include border(0.5px solid $color-2,  8px);
//      padding:15px 17px;
//      height: auto;
//      letter-spacing: 0.3px;
//      color:$color-9;
//      font-size:14px;
//      &::placeholder{
//           @include font($font-sm, $color-7);
//           text-transform: uppercase;
//           letter-spacing: 0.3px;
//           font-weight: 400;

//      }
// }

// .password-wrap{
//      div{
//           display:flex;
//           align-items:center;
//           position:relative;
//           .form-control{
//                    padding-right: 50px;
//           }
//           .input-group-append{
//                    position: absolute;
//                     right: 0;
//                     z-index: 2;
//                     top: 0;
//                     bottom: 0;
//                     width: 45px;
//                     justify-content: center;
//                     border-bottom-right-radius: 8px;
//                     border-top-right-radius: 8px;
//                     &:hover{
//                          cursor:pointer;
//                     }
//           }
//      }
// }
// .custom-radio{
//      display:flex;
//      .custom-control-label{
//           @include font(16px, $color-5);
//           line-height:25px;
//           font-weight:500;
//           &:before{
//                border:1px solid $color-8 ;
//           }
//            &:hover{
//                cursor:pointer;
//           }
//      }
//      .custom-control-inline{
//           margin-right:3rem;
         
//      }
// }
// .error-control{
//      border-color:$error;
//      &:focus{
//           border-color: $error;
//           box-shadow: 0 0 0 0.2rem rgba(217, 30, 34, 0.25%);
//      }
// }
// .error{
//      margin-bottom:10px;
//      p{
//           text-transform:capitalize;
//           text-align: right;
//           @include font($font-sm, $error);
//           @include flex-y-center;
//           justify-content: flex-end;
//      }
// }
form{
    border-radius: 4px;
    // overflow: hidden;
    label{
        @include font($font-xs, $label);
    }
        .form-control{
            height: 48px;
            background-color: $form-bg;
            border:none;
            font-size: $font-sm;
            border: 1px solid $border-color;
            color: $text-color;
            &::placeholder{
                @include font($font-sm, $color-7);
            }
            &:focus{
                box-shadow: none;
                outline: none;
                background-color: $form-bg;
            }
            @include lg{
                margin-bottom: 10px;
                border-radius: 5px  !important;
            }
        }
        .icon-append{
            position: absolute;
            z-index: 2;
            left: 10px;
        }
        .text-append{
            position: absolute;
            // z-index: 2;
            left: 40px;
            color: $color-7;
        }
        .btn{
            height: 48px !important;
            background: transparent linear-gradient(90deg, #F6B801 0%, #F59200 100%) 0% 0% no-repeat padding-box;
            border: none !important;
            color: $white !important;
            border-radius: 4px;
            box-shadow: none !important;
            font-size: 14px;
            @media screen and (max-width: 991px) {
                width: 100%;
                
            }
        }
    
}
label{
    color: $color-7;
}

.form-group{
    .text-danger{
        margin: 5px 0;
        font-size: 13px;
    }
}

.form-error{
    border:1px solid #dc3545 !important;
}

.loader {
    .skeleton {
      background: #fff;
      border: 1px solid;
      border-color: #e5e6e9 #dfe0e4 #d0d1d5;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      margin: 10px 15px;
      
      &.skeleton--card {
        width: 500px;
        display: inline-block;
        vertical-align: text-top;
      }
      
      
      .skeleton--content {
        height: 150px;
        padding: 15px;
        position: relative;
      
        .loader {
          background: #f6f7f8;
          -webkit-animation-duration: 1s;
          -webkit-animation-fill-mode: forwards;
          -webkit-animation-iteration-count: infinite;
          -webkit-animation-name: placeholderSkeleton;
          -webkit-animation-timing-function: linear;
          background-image: -webkit-gradient(linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8));
    background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    height: 104px;
    position: relative;
        }
        
        .skeleton--content-wrapper {}
        
        .skeleton--table {
          .skeleton--tr {
            display: flex;
            .skeleton--th {
              flex: 1 1 100%;
              height: 15px;
              margin: 5px 10px 15px;
            }
            .skeleton--td {
              flex: 1 1 100%;
              height: 10px;
              margin: 5px 10px;
            }
            
            .skeleton--td__2 {
              flex-basis: 300%;
            }
            .skeleton--td__3 {
              flex-basis: 500%;
            }
            .skeleton--td__4 {
              flex-basis: 700%;
            }
            .skeleton--td__5 {
              flex-basis: 900%;
            }
          }
        }
        
        .skeleton--title {
          margin: 5px 10px;
          height: 20px;
          width: 200px;
        }
        
        .skeleton--hr {
          height: 2px;
          width: calc(100% - 20px);
          margin: 0 10px 10px;
        }
        
        .skeleton--line {
          height: 10px;
          width: calc(100% - 20px);
          margin: 10px;
          
          &.skeleton--line__short {
            width: 120px;
          }
        }
        
        .skeleton--circle {
          margin: 5px 10px 10px;
          height: 60px;
          width: 60px;
          border-radius: 10px;
        }
        
        .fl {
          display: inline-block;
          width: auto;
          vertical-align: text-top;
        }
      }
    }
  }
  
  @-webkit-keyframes placeholderSkeleton {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  