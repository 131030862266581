@mixin font($size:14px, $color: $white, $weight:$regular) {
  font-size: $size;
  color:$color;
  font-weight: $weight;
}
@mixin border-radius($radius:50%){
  border-radius:$radius ;
  -webkit-border-radius:$radius ;
  -moz-border-radius:$radius ;
  -ms-border-radius:$radius ;
  -o-border-radius:$radius ;
}

@mixin button($padding, $bg-color) {
  @include font($size, $color);
  padding:$padding;
  background-color: $bg-color;
}

@mixin flex-center($display: flex,$align-items: center,$justify-content: center){
  display: $display;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin flex-x-center($display: flex,$justify-content: center){
  display: $display;
  justify-content: $justify-content;
}

@mixin flex-y-center($display: flex,$align-items: center){
  display: $display;
  align-items: $align-items;
}

@mixin transition($transition){
  -webkit-transition: $transition;
  transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
}


@mixin bg-cover($size:cover, $position:relative, $width:100%){
  background-size: $size !important;
    position: $position;
    width: $width;
}

@mixin  absolute($position:absolute, $top:0, $right:0,){
  position: $position;
  top:$top;
  right: $right;
}