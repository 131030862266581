
.booking-process{
 padding:30px 0 20px 0;
}
.progress-indicator {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex
}

.progress-indicator.stacked {
	display: block
}

.progress-indicator>li {
	-ms-flex: 1;
	-webkit-flex: 1;
	-moz-flex: 1;
	flex: 1
}

.progress-indicator {
	margin: 0 0 ;
	padding: 0;
	font-size: 80%;
	text-transform: uppercase
}

.progress-indicator>li {
	list-style: none;
	text-align: center;
	width: auto;
	padding: 0;
	margin: 0;
	position: relative;
	text-overflow: ellipsis;
	color: $text-color;
	display: block
}

.progress-indicator>li:hover {
	color: #6f6f6f
}

.progress-indicator>li.completed,
.progress-indicator>li.completed .bubble {
	color: $active-color;
}

.progress-indicator>li .bubble {
	border-radius: 1000px;
	width: 20px;
	height: 20px;
	background-color: $text-color;
	display: block;
	margin: 0 auto .5em;
	border-bottom: 1px solid #888
}

.progress-indicator>li .bubble:after,
.progress-indicator>li .bubble:before {
	display: block;
	position: absolute;
	top: 9px;
	width: 100%;
	height: 3px;
	content: '';
	background-color: $text-color
}

.progress-indicator>li.completed .bubble,
.progress-indicator>li.completed .bubble:after,
.progress-indicator>li.completed .bubble:before {
	background-color: $info;
	border-color: transparent;
}

.progress-indicator>li .bubble:before {
	left: 0
}

.progress-indicator>li .bubble:after {
	right: 0
}

.progress-indicator>li:first-child .bubble:after,
.progress-indicator>li:first-child .bubble:before {
	width: 50%;
	margin-left: 50%
}

.progress-indicator>li:last-child .bubble:after,
.progress-indicator>li:last-child .bubble:before {
	width: 50%;
	margin-right: 50%
}


