.details-card{
    h3{
        font-size: 18px;
    }
}
.hotel-gallery{
    .carousel.carousel-slider{
        height: 400px;
    }
    
    .thumbs-wrapper {
        .thumbs{
            display: flex;
            .thumb{
                width: 200px !important;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    
    .slider{
        .slide{
            div{
                height: 400px;
                img{
                    width: 100%;
                    object-fit: cover;
                    height: 100%;
                }
            }
        }
    }
    .carousel .slide{
        .legend {
            transition: all .5s ease-in-out;
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            margin-left: 0; 
            width: 100%;
            border-radius: 10px;
            background: #ffffff;
            color: #f59800;
            padding: 10px;
            font-size: 14px;
            text-align: center;
            transition: opacity .35s ease-in-out;
            border-radius: 0;
            border: 1px solid #ececec;
        }
    }
}

.bold{
    font-weight: 600;
}

.details-card-payment{
    table{
        tr{
            th, td{
                padding: 10px;
            }
            td{
                text-align: right;
                
            }
        }
        tbody{
            tr{
                td{
                    text-align: right;
                }
            }
        }
    }
    .total-payment{
        font-size: 18px;
       
        tr{
            td, th{
                padding: 10px;
                background-color: #f1f4f3;
            }
        }
    }
}


.Checking-points {
    .modal-title{
        font-size: 16px;
    }

}

.modal-footer{
    .btn{
        width: auto;
        height: 40px;
        padding: 5px 30px;
        font-size: 14px;
        text-transform: uppercase;
    }
}