

.slick-prev, .slick-next{
    height: 48px;
    width: 48px;
    background: $color-3;
    @include border-radius(50%);
   @include flex-center;
   @include transition(all ease .2s);
   border: 1px solid $color-3;
   z-index: 9;
   box-shadow: 0px 0px 12px 0px rgba(79, 37, 225, 0.03);
    &:before{
        @include font(20px, $color-4);
        font-family: initial;
    }
    &:hover{
        background: $white;
    }
}
.slick-prev{
    @include sm{
        left: 0px;
    }
    &:before{
        content: '‹';
      
    }
}

.slick-next{
    @include sm{
        right: 0px;
    }
    &:before{
        content: '›';
      
    }
}

.hide-btn{
    .slick-arrow {
        display: none !important;
    }
}
.show-hide-btn{
    .slick-arrow {
        display: none !important;
        @include rwd(1299){
            display: flex !important;
        }
    }
    
}
.slick-slide{
padding:15px;
}