.filter-sidebar{
    background-color: $white;
    padding:20px;
    position: sticky;
    top: 0;
    height: 100%;
    overflow: auto;
    max-height: 530px;
    @include border-radius(5px);
    h3{
        @include font(20px, $heading);
        text-transform: capitalize;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            height: 2px;
            background-color: $heading-border;
            width: 62px;
            border-radius: 2px;
            bottom: -5px;
            left: 0;
        }
    }
}
.filter-wrap{
    border-bottom:1px solid $border-color;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-transform: capitalize;
    
    h5{
        @include font($font-regular, $heading, $medium);
        margin-bottom: 20px;
    }
    .form-group{
        margin-bottom: 7px;
    }
}

.reserve-wrapper{
    &-inner{
        background: #ffffff;
        padding: 20px;
        border-radius: 8px;
        margin-bottom: 20px;
        .headings{
            border-bottom: 1px solid #E3E3E3;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        h5{
            font-size: 18px;
            color: $color-7;
            font-weight: $regular;
           
        }
    }
    .form-outer{
        .form-control{
            border-radius: 2px;
            border:1px solid #ECECEC;
            background-color: #F8F7F5;
            height: 48px;
            font-size: 14px;
            color:$color-7;
            &::placeholder{
                font-size: 14px;
                color:$color-7;
            }
        }
    }
}

.content-wrapper{
    color: $color-7;
}
.maps-wrappers{
    position: relative;
    height: 500px;
}
