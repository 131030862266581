@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');
body{
  margin:0;
  padding:0;
  font-family: 'Poppins', sans-serif;
  color: $primary-color;
  font-weight:$regular;
  font-size: $font-sm;
  background-color:$bg-color;
}

a, button, .btn{
  @include transition(all .5s ease);
  cursor: pointer;
}

a{
  color: $primary-color;
  &:hover{
    text-decoration:none;
  }
  &.bg-primary{
    &:hover{
      background-color:$color-4 !important;
    }
    
  }
  &.bg-secondary{
    &:hover{
      background-color: $secondary-color !important;
    }
  }
  &.text-secondary{
    &:hover, &:focus{
      color:#f73637 !important
    }
  }
  
}

.btn:focus{
  box-shadow:none;
  outline:0;
}


.text-dark {
  color: $primary-color;
}
.text-primary{
  color:$color-4 !important;
}
.text-secondary{
  color:$secondary-color !important;
}
ul{
  list-style: none;
  padding: 0;
}
.text-muted{
  color:$muted;
}

.hidden{
  display: none;
}

.bg{
  &-primary{
    background-color:$color-4 !important;
    
  }
  &-secondary{
    background-color: $secondary-color !important;
  }
}

.bg-cover{
  @include bg-cover;
  &:before{
    content: '';
    @include absolute;
    width: 100%;
    height: 100%;
    left: 0;
    display: block;
    background-color: rgba(113, 103, 255, 0.92) !important;
  }
}

.h-8 {
  height: 4rem ;
}
.w-8 {
  width: 4rem ;
}

.bg-white{
  background-color: $white !important;
}
.bg-success{
  background-color: $success !important;
}
.bg-success-transparent{
  background-color: rgba(62, 183, 80, 0.1) !important;
  
}
.bg-warning-transparent {
  background-color: rgba(255, 182, 9, 0.1) !important;
}
.bg-primary-transparent {
  background-color: rgba(113, 103, 255, 0.1) !important;
}
.bg-secondary-transparent {
  background-color: rgba(249, 103, 104, 0.1) !important;
}
.text-success {
  color: $success !important;
}
.text-warning {
  color: #ffb609 !important;
}

.badge{
  &-success{
    background-color: $success !important;
    color: $white;
  }
}

.leaflet-container{
  width: 100%;
  height: 100%;
}

